import * as React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { useLocale } from "@context/locale";

import Button from "@components/Button";
import ActualPromotions from "@components/ActualPromotions";
import OpinionCarousel from "@components/OpinionCarousel";
import ReservationTiles from "@components/ReservationTiles";
import BlogNews from "@components/BlogNews";
import Seo from "@components/Seo";

import Hero from "@page_components/home/Hero";
import OfferTile from "@page_components/home/OfferTile";

function IndexPageEN({ data }) {
  const { t } = useLocale();
  const { offer_data, blog_data, page_home_data } = data;
  const home_data = page_home_data?.nodes[0]?.pageHome;
  const reservation_items = page_home_data?.nodes[0]?.pageHome?.reservation;
  const hero_slides = home_data?.heroSlide;
  const meetUsImage = getImage(home_data?.meetUsImage?.gatsbyImage);
  // const instagram_posts = data?.allInstagramContent?.nodes;
  const seo = page_home_data.nodes[0]?.seo;

  return (
    <>
      <Seo title={seo?.title} description={seo?.metaDesc} />
      <section className="hero-section">
        <div className="container">
          <Hero autoplay slides_data={hero_slides} slide_change_time={10000} />
        </div>
      </section>

      <section className="meet-us-section">
        <div className="container">
          {!!meetUsImage && (
            <GatsbyImage
              className="meet-us-section__image"
              image={meetUsImage}
              alt="Gabinet kosmetyczny"
              objectFit="contain"
            />
          )}
          <div className="meet-us-section__description">
            <h2>{home_data?.meetUsTitle}</h2>
            <h4>{home_data?.meetUsSubtitle}</h4>
            <div
              dangerouslySetInnerHTML={{
                __html: home_data?.meetUsDescription,
              }}
            />

            <Button
              href={t("meet-us_link")}
              className="meet-us-section__button"
            >
              See more
            </Button>
          </div>
        </div>
      </section>

      <section className="offer-section">
        <div className="container">
          <h2>Offer</h2>
          <h4>What can we do for you?</h4>
          <div className="offer-section__album">
            {offer_data?.nodes?.map((item) => {
              return (
                <OfferTile
                  image={item?.featuredImage}
                  name={item?.title}
                  href={item?.uri}
                  key={item?.uri}
                />
              );
            })}
          </div>
        </div>
      </section>

      <section className="promotions-section">
        <div className="container">
          <ActualPromotions />
        </div>
      </section>

      <section className="opinions-section">
        <OpinionCarousel />
      </section>

      <section className="reservation-section">
        <div className="container">
          <h2 className="reservation-section__title">Visit booking</h2>
          <h4 className="reservation-section__subtitle">
            Many comfortable possibilities
          </h4>
          <ReservationTiles items={reservation_items} />
        </div>
      </section>

      {/* {instagram_posts?.length && (
        <section className="social-section">
          <div className="container">
            <h2 className="social-section__title">Social Media</h2>
            <h4 className="social-section__subtitle">
              See what's going on with us
            </h4>
            <div className="social-section__list">
              {instagram_posts?.map((post) => {
                const postThumbnail = getImage(
                  post?.localImage?.childImageSharp?.gatsbyImageData
                );
                return (
                  <a
                    href={post?.permalink}
                    target="_blank"
                    rel="noreferrer"
                    className="social-section__thumbnail"
                  >
                    <GatsbyImage image={postThumbnail} />
                  </a>
                );
              })}
            </div>
          </div>
        </section>
      )} */}

      {blog_data?.nodes?.length && (
        <section className="blog-section">
          <div className="container">
            <h2 className="blog-section__title">Blog</h2>
            <h4 className="blog-section__subtitle">Find out more</h4>
            <div className="blog-section__list">
              {blog_data?.nodes?.map((item, index) => {
                if (index < 4) {
                  const newsImage = getImage(
                    item?.featuredImage?.node?.gatsbyImage
                  );
                  return (
                    <BlogNews
                      blog_news_data={item}
                      image={newsImage}
                      key={item?.slug}
                    />
                  );
                }
                return null;
              })}
            </div>
            <Button className="blog-section__button" href="/blog">
              Read more
            </Button>
          </div>
        </section>
      )}
    </>
  );
}

export default IndexPageEN;

export const query = graphql`
  {
    offer_data: allWpPage(
      filter: {
        template: { templateName: { eq: "Oferta" } }
        language: { slug: { eq: "en" } }
      }
      sort: { fields: menuOrder, order: ASC }
    ) {
      nodes {
        uri
        title
        featuredImage {
          node {
            gatsbyImage(quality: 80, width: 261, height: 290)
          }
        }
      }
    }

    blog_data: allWpPost(
      sort: { fields: date, order: ASC }
      limit: 4
      filter: { language: { slug: { eq: "en" } } }
    ) {
      nodes {
        title
        categories {
          nodes {
            name
            slug
          }
        }
        content
        excerpt
        date(formatString: "MM.DD.YYYY")
        slug
        categories {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            gatsbyImage(width: 690, height: 410, quality: 100, fit: FILL)
            altText
          }
        }
      }
    }
    page_home_data: allWpPage(
      filter: { slug: { regex: "/strona-glowna-english/" } }
    ) {
      nodes {
        pageHome {
          heroSlide {
            heroDescription
            heroSubtitle
            heroTitle
            heroImage {
              gatsbyImage(
                width: 950
                height: 810
                quality: 100
                layout: FULL_WIDTH
              )
            }
          }
          meetUsTitle
          meetUsSubtitle
          meetUsDescription
          meetUsImage {
            gatsbyImage(width: 451, height: 686)
          }
          reservation {
            title
            description
            link
            image {
              gatsbyImage(width: 546)
            }
          }
        }
        seo {
          title
          metaDesc
        }
      }
    }

    # allInstagramContent(limit: 4) {
    # 	nodes {
    # 		permalink
    # 		localImage {
    # 			childImageSharp {
    # 				gatsbyImageData(
    # 					layout: CONSTRAINED
    # 					placeholder: BLURRED
    # 					width: 261
    # 				)
    # 			}
    # 		}
    # 	}
    # }
  }
`;
